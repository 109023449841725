
import React, { useState } from 'react'
import Layout from '../../../components/layout'
import styled, { keyframes } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core'
import { Colors } from '../../../constants'
import { navigate } from 'gatsby'

const PrivacyImg = require('../../../dist/images/cookies.svg');

export default () => {
    return (
        <Layout padTop={true} title={'Cookie Policy'}>
            <PolicyWrapper>
                <Container>
                <PolicySection spacing={3} container style={{marginBottom: '50px', borderBottom: `1px solid ${Colors.auxidusBlue}`}}>
                    <Grid item xs={12} sm={8}>
                        <HeaderText>
                            Cookie Policy
                        </HeaderText>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <HeaderImage />
                    </Grid>
                </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Preface
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                               Our <span style={{color: Colors.auxidusBlue, cursor: 'pointer'}} onClick={()=> navigate('/company/legal/privacy')}>Privacy Policy</span> outlines our principles for collecting, processing, and storing your information.
                               This policy explains how we incorporate cookies, as well as the capacity you, the viewer, have to control them.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                What Are Cookies
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                Cookies are small pieces of information stored on the user's computer or mobile device. Cookies can be used for many reasons, 
                                including personalization, session management and authentication. In our case, we use cookies solely for functionality and analytics. In other words, 
                                we gather information based on what pages are visited the most, and which links users are not interested in. We use that data to optimize our site to 
                                provide you, the consumer, a more positive user experience. The cookie types we use are outlined below.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Types of Cookies
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                <span style={{fontWeight: 'bold'}}>Performance</span> <br />
                                As a new company, we are consciously working to provide our users a more streamlined and enjoyable experience. Auxidus leverages a third-party analytics 
                                API, which let's us count the number of viewers that each page receives, as well as what content our users are not interested in.<br/><br/>
                                <span style={{fontWeight: 'bold'}}>Functionality</span> <br />
                                These cookies allow us to remember information relative to the behavior and design you desire while using our website. This may include remembering your region 
                                for any applicable translations, or themes that may change the appearance of our website.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Cookie Preferences
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                You, the viewer, are entitled to refuse cookies from our website at any time. Below is a list of resources to help you manage your cookie preferences, depending on your browser. <br />
                                <em>Do note however, that disabling cookies can potentially cause certain site functionalities 
                                to not work as intended. </em> <br/><br/>
                                        <a href={`https://www.allaboutcookies.org/manage-cookies/opera.html`} target="_blank" rel="norefferer">Opera</a>, {' '} 
                                        <a href={`https://www.allaboutcookies.org/manage-cookies/safari.html`} target="_blank" rel="norefferer">Safari</a>, {' '}                                     
                                        <a href={`https://www.allaboutcookies.org/manage-cookies/google-chrome.html`} target="_blank" rel="norefferer">Chrome</a>, {' '}                                     
                                        <a href={`https://www.allaboutcookies.org/manage-cookies/internet-explorer11.html`} target="_blank" rel="norefferer">IE 11+</a>, {' '}                                     
                                        <a href={`https://www.allaboutcookies.org/manage-cookies/firefox2-plus.html`} target="_blank" rel="norefferer">Firefox</a>
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Contact Us
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8} style={{marginBottom: '50px'}}>
                            <SectionBody>
                                If you have any questions or suggestions about our Cookie Policy, do not hesitate to contact us.
                            </SectionBody>
                        </Grid>
                    </PolicySection>
                </Container>
            </PolicyWrapper>
        </Layout>
    )
}


const HeaderText = styled(Typography)`
    line-height: 250px;
    font-size: 60px;
    color: ${Colors.auxidusBlue};
    font-weight: bold;
`;
const HeaderImage = styled.div`
    background: url(${PrivacyImg}) no-repeat center center;
    background-size: contain;
    height: 200px;
    width: auto;
    position: relative;
    margin: auto;
`;
const PolicyHeader = styled.div`
    
`;

const PolicySection = styled(Grid)`
`;
const PolicyWrapper = styled.div`
    margin-top: 50px;
`;
const SectionTitle = styled(Typography)`
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    color: ${Colors.auxidusBlue};
    @media only screen and (max-width: 800px) {
        text-align: left;
    }
`;
const SectionBody = styled(Typography)`
    color: gray;
    a {
        text-decoration: none;
        color: ${Colors.auxidusBlue};
    }
`;